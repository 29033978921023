.container {
  position: relative;
  min-height: 10vh;
  background: #020202;
  z-index: 100;
  padding: 3vh 0;
  margin-top: -3px;

  a {
    display: block;
    text-align: center;
    transform: scale(0.8);

    @media (min-width: 1000px) {
      transform: scale(1);
    }
  }
}

.hhgLogo {
  filter: invert(1);
}

.yolaLogo {
  width: 75px;

  @media (min-width: 800px) {
    margin-bottom: -7px;
  }
}

.openSourceLogo {
  font-size: 200%;
  font-family: monospace;
  color: #fff;
  text-decoration: none;
  letter-spacing: -0.1rem;
  line-height: 100%;
}

.resume {
  text-align: center;

  & > a {
    /* Prevent button from filling its container */
    display: inline-block;

    /* Align resume button with unaligned image assets */
    @media (min-width: 800px) {
      margin-top: -0.9rem;
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 90vw;

  & > * {
    flex: 1;
    margin: 1rem 0.2rem;
  }
}
