.columns {
  padding: 5vh 0;

  @media (min-width: 800px) {
    align-items: stretch;
    justify-content: space-between;
  }
}

.container {
  article > a {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    overflow: hidden;
    line-height: normal;
    transition: box-shadow 0.5s, transform 0.5s;
    margin: 0;

    &:hover {
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.55);
      transform: scale(1.03);
    }
  }

  @media (min-width: 800px) {
    article > a {
      margin: 0 0.5rem;
    }

    article:first-child > a {
      margin-right: 0.5rem;
    }

    article:last-child > a {
      margin-left: 0.5rem;
    }
  }

  @media (min-width: 1200px) {
    article > a {
      margin: 0 1.5rem;
    }

    article:first-child > a {
      margin-right: 1.5rem;
    }

    article:last-child > a {
      margin-left: 1.5rem;
    }
  }

  .content {
    flex: 1;
    border: 1px solid #eaeaea;
    border-radius: 0 0 1rem 1rem;
    border-top: 0;
    padding: 2rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: normal;
    font-size: 100%;
    text-align: left;
    color: #000;

    p {
      padding: 1rem 0;
      line-height: 150%;
      font-size: 90%;
    }

    h1,
    h2 {
      color: #000;
      letter-spacing: normal;
      font-size: 120%;
      line-height: normal;
      padding: 0.25rem 0;
      margin: 0;
      font-weight: bold;
    }

    h2 {
      font-size: 80%;
      color: #636363;
    }

    footer {
      text-decoration: none;
      color: #2e9649;
      font-weight: bold;
      padding: 1rem 0 30px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 0;
    }
  }
}

.tags {
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
  font-size: 70%;

  li {
    color: darken(#e1edae, 55%);
    font-weight: bold;
    display: inline-block;
    padding: 0.5rem;
    margin: 0.3rem;
    margin-left: 0;
    background-color: #e1edae;
    border-radius: 5px;
  }
}

.djTags {
  li {
    color: darken(#0bf, 25%);
    background-color: lighten(#0bf, 40%);
  }
}

.reduxTags {
  li {
    color: darken(#ff91a1, 20%);
    background-color: lighten(#ff91a1, 15%);
  }
}

.topOfStack {
  z-index: 100;
}

.djproxyBg,
.testtubeBg,
.reduxThunkMonitorBg {
  height: 200px;

  @media (min-width: 1000px) {
    height: 300px;
  }
}

.djproxyBg {
  background: #01a6fe url(./djproxy.webp) no-repeat top center;
  background-size: cover;
}

:global(.no-webp) .djproxyBg {
  background-image: url(./djproxy.jpg);
}

.testtubeBg {
  background: rgb(172, 1, 1) url(./tubes-rotated.svg) no-repeat center center;
  background-size: 115%;
}

.reduxThunkMonitorBg {
  background: #ff8ea5 url(./thunk.svg) no-repeat center center;
  background-size: 300% 150%;
}
