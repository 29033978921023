.container {
  p {
    font-size: 135%;
    line-height: 150%;
    font-weight: 300;
    opacity: 0.85;
  }

  h1 {
    font-size: 4rem;
  }
}
