.container {
  background: #373e45;
  color: #fff;
  font-size: 80%;

  @media print {
    display: none;
  }
}

.pill {
  display: inline-block;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: #fff;
  text-decoration: none;
  background: rgba(black, 0.1);
  transition: 0.5s background;
  cursor: pointer;
  line-height: 1rem;

  &:hover {
    background: rgba(black, 0.2);
  }
}

.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.columns {
  align-items: center;
}

.copyrightCol {
  p {
    display: inline-block;
    padding-right: 1rem;
  }

  ul {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0 0.5rem 0 0;
    }
  }
}

.socialBar {
  font-size: 150%;
  padding: 1rem 0;

  @media (min-width: 800px) {
    justify-content: flex-end;
  }
}
