.container {
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: space-between;

    @media print {
      padding: 0;
    }

    .text {
      @media (min-width: 800px) {
        min-width: 150px;
      }

      @media print {
        min-width: 80px;
      }
    }

    span:first-child,
    .text {
      line-height: 1rem;
      display: block;
      padding: 0 0.25rem;
    }
  }

  @media print, (min-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    vertical-align: middle;

    li {
      display: inline-flex;
      width: 100%;
      justify-content: flex-end;

      span:first-child,
      .text {
        flex: 1;
        padding: 0;
        margin-left: 1rem;
        text-align: right;
        white-space: nowrap;
      }
    }
  }
}
