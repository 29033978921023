@import-normalize;

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 18px;
  font-weight: 200;
  line-height: 2rem;
  text-align: center;

  @media screen {
    background: #373e45;
  }

  @media print {
    font-family: sans-serif;
  }
}

@page {
  size: auto;
  margin: 1in 1in 0.5in 1in;
}

#root {
  background: #fff;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 5rem;
  font-weight: 100;
  letter-spacing: 0.25rem;
}
