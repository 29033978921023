$accent-color: #c44e48;

.container {
  position: relative;
  padding: 3rem 0;

  @media print, (max-width: 800px) {
    padding: 1rem 0;
  }
}

.lolipop {
  @media print, (min-width: 800px) {
    position: absolute;
    top: 3rem;
    bottom: 4rem;
  }

  @media print {
    top: 1rem;
    bottom: 2rem;
  }

  svg {
    position: relative;
    z-index: 1;
    font-size: 150%;
    padding: 0.75rem;
    margin-left: -1.25rem;

    @media print, (min-width: 800px) {
      margin: 0;
    }

    @media print {
      font-size: 120%;
      padding: 0.4rem 0.3rem;
    }
  }
}

.lolipopStick {
  background-color: $accent-color;
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;
  height: 4px;

  @media print, (min-width: 800px) {
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    height: auto;
    margin-left: -2px;
  }

  @media print {
    width: 2px;
    margin-left: -1px;
  }
}
