$accent-color: #c44e48;
$color: #7a7a7a;

.resume {
  color: $color;
  font-weight: 300;

  @media print {
    font-size: 10px;
    line-height: normal;
    color-adjust: exact;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 110%;
    padding: 0.5rem 0;
    margin: 0;

    @media print {
      padding: 0.25rem 0;
    }
  }

  strong {
    font-weight: bold;
  }

  p {
    font-size: 110%;
  }

  a,
  a:visited {
    color: $color;
    text-decoration: none;
  }

  main {
    margin: 10vh 0;

    @media print {
      margin: 2rem 0;
    }
  }

  header {
    font-size: 90%;

    @media screen and (min-width: 500px) {
      margin-top: 10vh;
      font-size: 100%;
    }

    h1,
    h2 {
      text-transform: uppercase;
      font-weight: 300;

      @media print {
        font-weight: 700;
      }
    }

    h1 {
      font-size: 350%;
      padding-top: 0;
    }

    h2 {
      font-size: 200%;
      color: $accent-color;
    }
  }

  section {
    text-align: left;

    @media print {
      font-size: 10px;
    }

    div {
      text-align: left;
    }

    h1,
    h2,
    h3 {
      letter-spacing: normal;
      font-weight: 300;
      margin: 0;
    }

    h1 {
      font-size: 140%;
      color: $accent-color;
    }

    h2 {
      font-size: 160%;
    }

    h3 {
      font-size: 120%;
      color: rgba($color, 0.6);
      font-style: oblique;
    }
  }

  /* The first column of resume content columns is a date range, split in the
    middle by a line break. At lower resolutions, we want it rendered in
    english as "start date to end date". This egregious hack achieves that
    by replacing the br with "to" */
  .timeRange {
    align-self: flex-start;
    margin-bottom: -1.5rem;

    /* Always prevent the timeRange column from expanding to take up extra space
      even when subsequent columns are narrow. */
    flex: 0;
    flex-basis: 0;

    @media screen and (max-width: 800px) {
      br {
        display: inline-block;
        content: '';

        &::after {
          padding: 0 0.5em;
          display: inline-block;
          content: 'to';
        }
      }
    }

    @media print, (min-width: 800px) {
      text-align: left;
      margin: 1rem 5rem 0 8rem;
    }

    @media print {
      font-size: 8px;
      text-align: left;
      margin: 1rem 2rem 0 3rem;
    }
  }

  .moreLink {
    font-size: 90%;

    @media (min-width: 800px) {
      text-align: right;
      margin-left: 5rem;
    }

    @media print {
      display: none;
    }

    svg {
      display: inline-block;
      padding-left: 0.5rem;
    }

    a {
      text-decoration: underline;
    }

    a:hover {
      color: $accent-color;
    }
  }

  .subColumns {
    text-align: left;

    p {
      padding: 0;
    }

    p:first-child {
      @media print, (min-width: 800px) {
        padding-right: 2rem;
      }
    }
  }
}
