.container {
  display: inline-block;
  background: transparent;
  user-select: none;
  border: 2px solid rgba(#fff, 0.15);
  border-radius: 20px;
  width: auto;
  padding: 15px 5px;

  .content {
    display: inline-block;
    border-radius: 15px;
    box-shadow: 0 0 10vw 0.1vw rgba(#fff, 0.4);

    video {
      display: flex;
      min-width: 15vw;
      max-width: 75vw;
      border-radius: 15px;
      overflow: hidden;
    }
  }
}
