$background-color: #36bc59;

.button {
  font-size: 20.2px;
  font-weight: 400;
  display: inline-flex;
  flex-direction: row;
  box-sizing: border-box;
  background: $background-color;
  color: rgba(white, 0.8);
  margin: 0.5rem;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none;
    background-color: darken($background-color, 5%);
    color: rgba(white, 0.9);
  }

  span {
    display: block;
    padding: 0 0.75rem 0 1rem;
    transform: scale(0.8);
  }

  &:disabled {
    color: #fff6;
  }
}

.withIcon {
  padding-left: 0;
  padding-right: 1rem;
}
