.container {
  background: #373e45;
  padding: 10vh 0;
  color: #fff;
}

.content {
  padding-right: 10%;
  margin-bottom: 5vh;
}

.form {
  padding-left: 0 10%;
}
