.intro {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  margin: 1rem 2rem 1rem 0;
  overflow: hidden;
  font-size: 200%;
  clip-path: polygon(
    100% 50%,
    100% 56.6%,
    100% 59.3%,
    100% 61.4%,
    99.9% 63.2%,
    99.9% 64.8%,
    99.9% 66.2%,
    99.8% 67.5%,
    99.8% 68.7%,
    99.7% 69.8%,
    99.6% 70.8%,
    99.5% 71.8%,
    99.5% 72.8%,
    99.4% 73.7%,
    99.3% 74.6%,
    99.1% 75.4%,
    99% 76.3%,
    98.9% 77%,
    98.8% 77.8%,
    98.6% 78.5%,
    98.5% 79.2%,
    98.3% 79.9%,
    98.1% 80.6%,
    98% 81.3%,
    97.8% 81.9%,
    97.6% 82.5%,
    97.4% 83.1%,
    97.2% 83.7%,
    97% 84.3%,
    96.8% 84.8%,
    96.5% 85.4%,
    96.3% 85.9%,
    96% 86.4%,
    95.8% 86.9%,
    95.5% 87.4%,
    95.3% 87.9%,
    95% 88.3%,
    94.7% 88.8%,
    94.4% 89.2%,
    94.1% 89.7%,
    93.8% 90.1%,
    93.4% 90.5%,
    93.1% 90.9%,
    92.8% 91.3%,
    92.4% 91.7%,
    92% 92%,
    91.7% 92.4%,
    91.3% 92.8%,
    90.9% 93.1%,
    90.5% 93.4%,
    90.1% 93.8%,
    89.7% 94.1%,
    89.2% 94.4%,
    88.8% 94.7%,
    88.3% 95%,
    87.9% 95.3%,
    87.4% 95.5%,
    86.9% 95.8%,
    86.4% 96%,
    85.9% 96.3%,
    85.4% 96.5%,
    84.8% 96.8%,
    84.3% 97%,
    83.7% 97.2%,
    83.1% 97.4%,
    82.5% 97.6%,
    81.9% 97.8%,
    81.3% 98%,
    80.6% 98.1%,
    79.9% 98.3%,
    79.2% 98.5%,
    78.5% 98.6%,
    77.8% 98.8%,
    77% 98.9%,
    76.3% 99%,
    75.4% 99.1%,
    74.6% 99.3%,
    73.7% 99.4%,
    72.8% 99.5%,
    71.8% 99.5%,
    70.8% 99.6%,
    69.8% 99.7%,
    68.7% 99.8%,
    67.5% 99.8%,
    66.2% 99.9%,
    64.8% 99.9%,
    63.2% 99.9%,
    61.4% 100%,
    59.3% 100%,
    56.6% 100%,
    50% 100%,
    43.4% 100%,
    40.7% 100%,
    38.6% 100%,
    36.8% 99.9%,
    35.2% 99.9%,
    33.8% 99.9%,
    32.5% 99.8%,
    31.3% 99.8%,
    30.2% 99.7%,
    29.2% 99.6%,
    28.2% 99.5%,
    27.2% 99.5%,
    26.3% 99.4%,
    25.4% 99.3%,
    24.6% 99.1%,
    23.7% 99%,
    23% 98.9%,
    22.2% 98.8%,
    21.5% 98.6%,
    20.8% 98.5%,
    20.1% 98.3%,
    19.4% 98.1%,
    18.7% 98%,
    18.1% 97.8%,
    17.5% 97.6%,
    16.9% 97.4%,
    16.3% 97.2%,
    15.7% 97%,
    15.2% 96.8%,
    14.6% 96.5%,
    14.1% 96.3%,
    13.6% 96%,
    13.1% 95.8%,
    12.6% 95.5%,
    12.1% 95.3%,
    11.7% 95%,
    11.2% 94.7%,
    10.8% 94.4%,
    10.3% 94.1%,
    9.9% 93.8%,
    9.5% 93.4%,
    9.1% 93.1%,
    8.7% 92.8%,
    8.3% 92.4%,
    8% 92%,
    7.6% 91.7%,
    7.2% 91.3%,
    6.9% 90.9%,
    6.6% 90.5%,
    6.2% 90.1%,
    5.9% 89.7%,
    5.6% 89.2%,
    5.3% 88.8%,
    5% 88.3%,
    4.7% 87.9%,
    4.5% 87.4%,
    4.2% 86.9%,
    4% 86.4%,
    3.7% 85.9%,
    3.5% 85.4%,
    3.2% 84.8%,
    3% 84.3%,
    2.8% 83.7%,
    2.6% 83.1%,
    2.4% 82.5%,
    2.2% 81.9%,
    2% 81.3%,
    1.9% 80.6%,
    1.7% 79.9%,
    1.5% 79.2%,
    1.4% 78.5%,
    1.2% 77.8%,
    1.1% 77%,
    1% 76.3%,
    0.9% 75.4%,
    0.7% 74.6%,
    0.6% 73.7%,
    0.5% 72.8%,
    0.5% 71.8%,
    0.4% 70.8%,
    0.3% 69.8%,
    0.2% 68.7%,
    0.2% 67.5%,
    0.1% 66.2%,
    0.1% 64.8%,
    0.1% 63.2%,
    0% 61.4%,
    0% 59.3%,
    0% 56.6%,
    0% 50%,
    0% 43.4%,
    0% 40.7%,
    0% 38.6%,
    0.1% 36.8%,
    0.1% 35.2%,
    0.1% 33.8%,
    0.2% 32.5%,
    0.2% 31.3%,
    0.3% 30.2%,
    0.4% 29.2%,
    0.5% 28.2%,
    0.5% 27.2%,
    0.6% 26.3%,
    0.7% 25.4%,
    0.9% 24.6%,
    1% 23.7%,
    1.1% 23%,
    1.2% 22.2%,
    1.4% 21.5%,
    1.5% 20.8%,
    1.7% 20.1%,
    1.9% 19.4%,
    2% 18.7%,
    2.2% 18.1%,
    2.4% 17.5%,
    2.6% 16.9%,
    2.8% 16.3%,
    3% 15.7%,
    3.2% 15.2%,
    3.5% 14.6%,
    3.7% 14.1%,
    4% 13.6%,
    4.2% 13.1%,
    4.5% 12.6%,
    4.7% 12.1%,
    5% 11.7%,
    5.3% 11.2%,
    5.6% 10.8%,
    5.9% 10.3%,
    6.2% 9.9%,
    6.6% 9.5%,
    6.9% 9.1%,
    7.2% 8.7%,
    7.6% 8.3%,
    8% 8%,
    8.3% 7.6%,
    8.7% 7.2%,
    9.1% 6.9%,
    9.5% 6.6%,
    9.9% 6.2%,
    10.3% 5.9%,
    10.8% 5.6%,
    11.2% 5.3%,
    11.7% 5%,
    12.1% 4.7%,
    12.6% 4.5%,
    13.1% 4.2%,
    13.6% 4%,
    14.1% 3.7%,
    14.6% 3.5%,
    15.2% 3.2%,
    15.7% 3%,
    16.3% 2.8%,
    16.9% 2.6%,
    17.5% 2.4%,
    18.1% 2.2%,
    18.7% 2%,
    19.4% 1.9%,
    20.1% 1.7%,
    20.8% 1.5%,
    21.5% 1.4%,
    22.2% 1.2%,
    23% 1.1%,
    23.7% 1%,
    24.6% 0.9%,
    25.4% 0.7%,
    26.3% 0.6%,
    27.2% 0.5%,
    28.2% 0.5%,
    29.2% 0.4%,
    30.2% 0.3%,
    31.3% 0.2%,
    32.5% 0.2%,
    33.8% 0.1%,
    35.2% 0.1%,
    36.8% 0.1%,
    38.6% 0%,
    40.7% 0%,
    43.4% 0%,
    50% 0%,
    56.6% 0%,
    59.3% 0%,
    61.4% 0%,
    63.2% 0.1%,
    64.8% 0.1%,
    66.2% 0.1%,
    67.5% 0.2%,
    68.7% 0.2%,
    69.8% 0.3%,
    70.8% 0.4%,
    71.8% 0.5%,
    72.8% 0.5%,
    73.7% 0.6%,
    74.6% 0.7%,
    75.4% 0.9%,
    76.3% 1%,
    77% 1.1%,
    77.8% 1.2%,
    78.5% 1.4%,
    79.2% 1.5%,
    79.9% 1.7%,
    80.6% 1.9%,
    81.3% 2%,
    81.9% 2.2%,
    82.5% 2.4%,
    83.1% 2.6%,
    83.7% 2.8%,
    84.3% 3%,
    84.8% 3.2%,
    85.4% 3.5%,
    85.9% 3.7%,
    86.4% 4%,
    86.9% 4.2%,
    87.4% 4.5%,
    87.9% 4.7%,
    88.3% 5%,
    88.8% 5.3%,
    89.2% 5.6%,
    89.7% 5.9%,
    90.1% 6.2%,
    90.5% 6.6%,
    90.9% 6.9%,
    91.3% 7.2%,
    91.7% 7.6%,
    92% 8%,
    92.4% 8.3%,
    92.8% 8.7%,
    93.1% 9.1%,
    93.4% 9.5%,
    93.8% 9.9%,
    94.1% 10.3%,
    94.4% 10.8%,
    94.7% 11.2%,
    95% 11.7%,
    95.3% 12.1%,
    95.5% 12.6%,
    95.8% 13.1%,
    96% 13.6%,
    96.3% 14.1%,
    96.5% 14.6%,
    96.8% 15.2%,
    97% 15.7%,
    97.2% 16.3%,
    97.4% 16.9%,
    97.6% 17.5%,
    97.8% 18.1%,
    98% 18.7%,
    98.1% 19.4%,
    98.3% 20.1%,
    98.5% 20.8%,
    98.6% 21.5%,
    98.8% 22.2%,
    98.9% 23%,
    99% 23.7%,
    99.1% 24.6%,
    99.3% 25.4%,
    99.4% 26.3%,
    99.5% 27.2%,
    99.5% 28.2%,
    99.6% 29.2%,
    99.7% 30.2%,
    99.8% 31.3%,
    99.8% 32.5%,
    99.9% 33.8%,
    99.9% 35.2%,
    99.9% 36.8%,
    100% 38.6%,
    100% 40.7%,
    100% 43.4%
  );

  svg {
    z-index: 1;
  }
}

.content {
  list-style-type: none;
  text-align: left;

  li {
    .icon {
      float: left;
    }

    @media (min-width: 500px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 1rem;

      .icon {
        float: none;
      }
    }
  }
}

.demo {
  text-align: center;

  @media (min-width: 800px) {
    text-align: right;
  }

  margin: 10vh 0;
}
