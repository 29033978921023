.container {
  display: flex;
  box-sizing: border-box;
  margin: 10vw 0;
  border: 1px solid rgba(#000, 0.1);
  padding: 5px;
  padding-top: 15px;
  border-radius: 15px;
}

.content {
  flex: 1;
  box-sizing: border-box;
  flex-direction: column;
  width: 1022px;
  height: 766px;
  border-radius: 15px;
  border: 1px solid rgba(#ddd, 0.5);
  color: #fff;
  padding: 30% 5% 0 5%;
  text-align: left;
  box-shadow: 0 0 10vw 1rem rgba(#b84fe9, 0.6);
  max-width: 100%;

  h1,
  h2 {
    letter-spacing: normal;
    line-height: normal;
    font-weight: 800;
    margin: 0.5rem 0;
  }

  .cursor {
    display: inline-block;
    border-left: 0.05em solid rgba(#fff, 0.75);
    animation: blink-caret 0.75s step-end infinite;
  }
}

:global(.no-webp) .content {
  background: #d279f9 url('./frame.jpg') top left no-repeat;
  background-size: cover;
}

:global(.webp) .content {
  background: #d279f9 url('./frame.webp') top left no-repeat;
  background-size: cover;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
